<div class="filter-sort-container d-flex align-items-center" data-id="Detailed View Filter"
  [class]="isFiltering ? 'active' : 'deactive'">
  <div class='cell pointer'>
    <i [class]="'icon ' + (isFiltering? 'i-filter-on':'i-filter-off')" [matMenuTriggerFor]="menu" data-id="filterIcon"></i>
  </div>
  <mat-menu #menu="matMenu" class="table-card-filter-menu light-theme">
    @for (option of optionsFiltered; track option.key) {
      <ng-template let-col
        [ngTemplateOutletContext]="{$implicit: getColumnByOption(option)}"
        [ngTemplateOutlet]="column" #column
        >
        <button mat-menu-item [matMenuTriggerFor]="subMenu" (menuOpened)="menuOpened.emit(col)">
          <i [class.hidden]="!isOptionFiltered(option.key)" class="filter-point icon i-v-black xs"></i> {{col?.header}}
        </button>
        <mat-menu #subMenu="matMenu" class="table-card-filter-menu light-theme" (close)="closeMenu(col)">
          <form class="filter-form">
            @if (col?.searchableFilter) {
              <mat-form-field appearance="fill" smClickStopPropagation class="tags-menu-input w-100 no-bottom">
                <input matInput (input)="setSearchTerm($event, option.key)" name="filter" [(ngModel)]="searchTerms[option.key]" autocomplete="off">
                <i matSuffix class="fa me-3" [class]="searchTerms[option.key] ? 'fa-times pointer' : 'fa-search'" (click)="searchTerms[option.key] && clearSearch(option.key)"></i>
              </mat-form-field>
            }
          </form>
          @if (col?.andFilter) {
            <div class="filter-type" smClickStopPropagation>
              <span class="link me-3" [class.selected]="filterMatch[option.key] !== 'AND'"
              (click)="toggleCombination(option.key)">Any</span>
              <span class="link" [class.selected]="filterMatch[option.key] === 'AND'"
              (click)="toggleCombination(option.key)">All</span>
            </div>
          }
          @if (col) {
            <div class="results" [class.scroll]="col.searchableFilter" smClickStopPropagation>
              <sm-checkbox-three-state-list
                [supportExcludeFilter]="col.excludeFilter"
                [checkedList]="value[col.id]"
                [options]="option.value | filter : searchTerms[option.key]: 'label'"
                (filterChanged)="emitFilterChangedCheckBox(option.key, $event)"
              > </sm-checkbox-three-state-list>
            </div>
          }
          @if (option.key==='tags') {
            <div class="fixed-options">
              <mat-divider></mat-divider>
              @if (subOptions?.length) {
                <div class="fixed-options-subheader">
                  {{fixedOptionsSubheader}}
                </div>
              }
              @for (option of subOptions; track trackByLabel($index, option)) {
                <sm-menu-item
                  [itemLabel]="option.label"
                  [checked]="subValue?.includes(option.value)" [selectable]="true" [itemValue]="option.value"
                  (itemClicked)="onSubFilterChanged({id :'system_tags'}, $event)"
                ></sm-menu-item>
              }
            </div>
          }
          @if (col.columnExplain && optionsFiltered.length) {
            <div class="column-explanation">{{col.columnExplain}}</div>
          }
        </mat-menu>
      </ng-template>
    }
    <hr>
      <div mat-menu-item class="clear-all mat-menu-item" (click)="isFiltering && clearAll.emit()" [disabled]="!isFiltering">Clear all</div>
    </mat-menu>
  </div>

